(function ($) {
  Drupal.behaviors.cardView = {
    attach: function (context, settings) {
      $('.card-display').hover(function () {
          $(this).find('.card-front').hide();
          $(this).find('.card-back').show();
        }, function() {
          $(this).find('.card-back').hide();
          $(this).find('.card-front').show();
      });
      $('td').on('show.bs.popover', function(){
        this.classList.add('active');
      });
      $('td').on('hide.bs.popover', function(){
        this.classList.remove('active');
      });
    }
  };
})(jQuery);
